// Clases que estilen los formularios

input[type='button'],
input[type='submit'],
input[type='reset'] {
  display: inline-block;
  margin: 5px 20px 40px 20px;
  padding: 15px 80px;

  color: white;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  background-color: #56baed;
  border: none;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #39ace7;
  }

  &:active {
    transform: scale(0.95);
  }
}

input[type='text'] {
  display: inline-block;
  width: 85%;
  margin: 5px;
  padding: 15px 32px;

  color: #0d0d0d;
  font-size: 16px;
  text-align: center;
  text-decoration: none;

  background-color: #f6f6f6;
  border: 2px solid #f6f6f6;
  border-radius: 5px 5px 5px 5px;

  transition: all 0.5s ease-in-out;

  &:focus {
    background-color: #ffffff;
    border-bottom: 2px solid #5fbae9;
  }

  &:placeholder {
    color: #cccccc;
  }
}

/***** Validation ********/

.field-error {
  color: rgb(252, 0, 55);
}
