/* You can add global styles to this file, and also import other style files */

/* using Sass */

/* Import the functions first to use in the theme. */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';

// import only bootstrap grid and responsive system
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';

// PrimeNg
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

// import App global styles
@import './styles/forms';
@import './styles/layout';
@import './styles/primeng';
